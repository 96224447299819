import { useEffect, Fragment, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import io from 'socket.io-client';
// import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';

import { set as setAuth, setSupportNumber } from './store/authSlice';
import { set as setScreen } from './store/screenSlice';
import { set as setAvailability } from './store/availabilitySlice';
import axiosInstance from './utilities/axiosInstance';
import themes from './assets/themes';
import MuiSpinner from './components/muiSpinner';

// import OTPLoginDisabledPopup from './components/otpLoginDisabledPopup';

let Home = lazy(() => import('./pages/home/home'));
let NewHome = lazy(() => import('./pages/home/NewHome'));
let Categories = lazy(() => import('./pages/home/categories'));
let Brands = lazy(() => import('./pages/home/brands'));
let CreditAgreement = lazy(() => import('./pages/home/creditAgreement'));
let MultiProductPage = lazy(() => import('./pages/home/multiProductPage'));
let SingleProductPage = lazy(() => import('./pages/home/singleProductPage'));
let CataloguesPage = lazy(() => import('./pages/home/catalogues'));
let BillsPage = lazy(() => import('./pages/home/bills'));

// let Auth = lazy(() => import('./pages/auth/auth'));
// let SignIn = lazy(() => import('./pages/auth/signIn'));
// let VerifyLoginOTP = lazy(() => import('./pages/auth/verifyLoginOTP'));
// let SignUp = lazy(() => import('./pages/auth/signup'));
// let VerifyPhoneOTP = lazy(() => import('./pages/auth/verifyPhoneOTP'));

let Profile = lazy(() => import('./pages/user/profile'));
let WishList = lazy(() => import('./pages/user/wishList'));
let Addresses = lazy(() => import('./pages/user/addresses'));
let AddNewAddress = lazy(() => import('./pages/user/addNewAddress'));
let EditAddress = lazy(() => import('./pages/user/editAddress'));
let ProfileDetails = lazy(() => import('./pages/user/profileDetails'));
let ProfileDetail = lazy(() => import('./pages/user/profileDetail'));
let Update = lazy(() => import('./pages/user/update'));

let Partner = lazy(() => import('./pages/partner/partner'));
let PartnerSignup = lazy(() => import('./pages/partner/partner-signup'));

let TermsAndCondition = lazy(() =>
  import('./pages/others/terms_and_condition')
);
let ShippingPolicy = lazy(() => import('./pages/others/shipping-policy'));
let PrivacyPolicy = lazy(() => import('./pages/others/privacy-policy'));
let FAQs = lazy(() => import('./pages/others/faqs'));
let PartnerTermsAndConditions = lazy(() =>
  import('./pages/others/partner-terms-and-conditions')
);
let PartnerPrivacyPolicy = lazy(() =>
  import('./pages/others/partner-privacy-policy')
);
let CancellationRefundAndReturnPolicy = lazy(() =>
  import('./pages/others/cancellation-return-and-refund-policy')
);
let AboutUs = lazy(() => import('./pages/others/about-us'));

let Checkout = lazy(() => import('./pages/checkout/checkout'));
let Cart = lazy(() => import('./pages/checkout/cart/cart'));
let OrderCart = lazy(() => import('./pages/checkout/cart/orderCart'));
let SampleCart = lazy(() => import('./pages/checkout/cart/sampleCart'));
let ABSD = lazy(() =>
  import('./pages/checkout/addBillingAndShippingDetails/absd')
);
let AddBillingAndShippingDetails = lazy(() =>
  import(
    './pages/checkout/addBillingAndShippingDetails/addBillingAndShippingDetails'
  )
);
let SelectAddress = lazy(() =>
  import('./pages/checkout/addBillingAndShippingDetails/selectAddress')
);
let AddNewAddress2 = lazy(() =>
  import('./pages/checkout/addBillingAndShippingDetails/addNewAddress')
);
let EditAddress2 = lazy(() =>
  import('./pages/checkout/addBillingAndShippingDetails/editAddress')
);
// let SelectGSTNumber = lazy(() => import('./pages/checkout/addBillingAndShippingDetails/selectGSTNumber'));
// let AD = lazy(() => import('./pages/checkout/ad/ad'));
// let ApplyDiscount = lazy(() => import('./pages/checkout/ad/applyDiscount'));
// let SelectDiscount = lazy(() => import('./pages/checkout/ad/selectDiscount'));
// let SelectPaymentMethod = lazy(() => import('./pages/checkout/selectPaymentMethod'));
// let ReviewOrder = lazy(() => import('./pages/checkout/reviewOrder'));
let NewReviewOrder = lazy(() => import('./pages/checkout/newReviewOrder'));
let OrderConfirmation = lazy(() =>
  import('./pages/checkout/orderConfirmation')
);
// let SelectPaymentMode = lazy(() => import('./pages/checkout/selectPaymentMode'));
let ProfileInformation = lazy(() =>
  import('./pages/desktopView/profileInformation')
);

let Orders = lazy(() => import('./pages/orders/orders'));
let O = lazy(() => import('./pages/orders/order/o'));
let Order = lazy(() => import('./pages/orders/order/order'));
let NewOrder = lazy(() => import('./pages/orders/order/newOrder'));
let Cancel = lazy(() => import('./pages/orders/order/cancel'));
let Return = lazy(() => import('./pages/orders/order/return'));
let ViewReturnProgress = lazy(() =>
  import('./pages/orders/order/view-return-progress')
);

let Profile2 = lazy(() => import('./pages/desktopView/profile2'));

let Footer = lazy(() => import('./containers/footer'));

// let SideBar = lazy(() => import('./containers/sideBar'));
let Auth = lazy(() => import('./containers/auth/auth'));
let CheckPincode = lazy(() => import('./containers/checkPincode'));
let Modal = lazy(() => import('./containers/modal'));
// let P = lazy(() => import('./pages/desktopView/p'));
let FAQs2 = lazy(() => import('./pages/others/faqs2'));
let TermsAndCondition2 = lazy(() =>
  import('./pages/others/terms_and_condition2')
);
let PrivacyPolicy2 = lazy(() => import('./pages/others/privacy-policy2'));

let EstimateButton = lazy(() => import('./components/estimateButton'));
let Estimate = lazy(() => import('./containers/estimate'));
let WelcomeModal = lazy(() => import('./containers/welcomeModal'));
let MaintenancePage = lazy(() => import('./pages/isMaintenance'));

library.add(fas, far, fab);

function App() {
  // const analytics = getAnalytics();

  const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

  const dispatch = useDispatch();
  const screen = useSelector((state) => state.screen);
  const theme = screen.theme;
  const auth = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  useEffect(() => {
    window.rupee = '₹';
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      dispatch(setScreen({ screenWidth: window.innerWidth }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    axiosInstance
      .get('/others/getSupportNumbers')
      .then((res) => {
        dispatch(setSupportNumber(res.data.data.phone));
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axiosInstance
      .get('/user')
      .then((res) => {
        dispatch(
          setAuth({
            loading: false,
            authenticated: true,
            user: res.data.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setAuth({
            loading: false,
            authenticated: false,
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = auth.user;

    if (!user) return;

    // setUserId(analytics, user._id);
    // setUserProperties(analytics, {
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   phone: user.phone,
    //   email: user.email,
    //   role: user.customerRole,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  useEffect(() => {
    if (auth.authenticated) {
      axiosInstance
        .get('/user/wishList')
        .then((res) => {
          dispatch(
            setAuth({
              wishList: res.data.data,
            })
          );
        })
        .catch((err) => {});

      axiosInstance
        .get('/user/cartCount')
        .then((res) => {
          dispatch(
            setAuth({
              cartCount: res.data.data,
            })
          );
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authenticated]);

  useEffect(() => {
    const pincode = localStorage.getItem('pincode');
    if (pincode) {
      axiosInstance
        .get(`/others/checkPincode/${pincode}`)
        .then((res) => {
          dispatch(setAvailability(res.data.data));
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckPincode = () => {
    dispatch(setScreen({ showCheckPincode: !screen.showCheckPincode }));
  };

  const handleShowEstimate = () => {
    dispatch(setScreen({ showEstimate: !screen.showEstimate }));
  };

  const closeWelcomeModal = () => {
    dispatch(setScreen({ showWelcomeModal: false }));
  };

  const user = auth.user;

  useEffect(() => {
    // if (process.env.REACT_APP_Socket === 'true') {
    window.socket = io('http://localhost:3002');
    // if (user) {
    //   // window.dataLayer = window.dataLayer || [];
    //   // window.dataLayer.push({
    //   //   'user_id': user?._id,
    //   //   'phone': user?.phone,
    //   //   'name': user?.displayName,
    //   //   'event': 'logged_in'
    //   // });
    //   const script = document.createElement('script');
    //   script.innerHTML = `
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({ 'user_id': '${user?._id}', 'event': 'logged_in' });
    //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //     })(window,document,'script','dataLayer','GTM-MSHRHRSG');  
    //   `
    //   document.head.appendChild(script);
    // }
    window.socket.emit('setup', user);
    window.socket.on('connected', () => {
      console.log('Socket Connected !');
    });
    // }
  }, [user]);

  useEffect(() => {
    if (user) {
      window.socket?.emit('New Notification', user);
    }
  }, [user]);

  return isMaintenance ? (
    <MaintenancePage />
  ) : auth?.loading ? (
    <MuiSpinner />
  ) : (
    <Fragment>
      {/* <OTPLoginDisabledPopup /> */}
      {/* <Suspense fallback={<MuiSpinner />}><SideBar /></Suspense> */}
      {pathname?.split('/')?.[1] !== 'credit-agreement' ? (
        <Suspense fallback={<MuiSpinner />}>
          <EstimateButton />
        </Suspense>
      ): ''}
      {screen.showAuth ? (
        <Suspense fallback={<MuiSpinner />}>
          <Auth />
        </Suspense>
      ) : null}

      {screen.showCheckPincode ? (
        <Suspense fallback={<MuiSpinner />}>
          <Modal
            active={screen.showCheckPincode}
            onClick={() => handleCheckPincode()}
          >
            <CheckPincode onClick={() => handleCheckPincode()} />
          </Modal>
        </Suspense>
      ) : null}

      {screen.showEstimate ? (
        <Suspense fallback={<MuiSpinner />}>
          <Modal active={screen.showEstimate} onClick={() => {}}>
            <Estimate onClick={() => handleShowEstimate()} />
          </Modal>
        </Suspense>
      ) : null}

      {screen.showWelcomeModal ? (
        <Suspense fallback={<MuiSpinner />}>
          <Modal active={screen.showWelcomeModal} onClick={() => {}}>
            <WelcomeModal onClick={() => closeWelcomeModal()} />
          </Modal>
        </Suspense>
      ) : null}

      <div
        className="w-full min-w-full max-w-full min-h-screen relative"
        style={{
          backgroundColor: themes[theme].primaryBGColor,
        }}
      >
        <ToastContainer autoClose={3000} />

        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <NewHome />
              </Suspense>
            }
          />
          {/* <Route
            path="/new-home"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <NewHome />
              </Suspense>
            }
          /> */}
          <Route
            path="/categories"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <Categories />
              </Suspense>
            }
          />
          <Route
            path="/brands"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <Brands />
              </Suspense>
            }
          />
          <Route
            path="/products"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <MultiProductPage />
              </Suspense>
            }
          />
          <Route
            path="/products/:id"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <SingleProductPage />
              </Suspense>
            }
          />

          <Route
            path="/terms-and-conditions"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <TermsAndCondition />
              </Suspense>
            }
          />
          <Route
            path="/shipping-policy"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <ShippingPolicy />
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path="/faqs"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <FAQs />
              </Suspense>
            }
          />
          <Route
            path="/cancellation-Refund-and-Return-policy"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <CancellationRefundAndReturnPolicy />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="/partner-terms-and-conditions"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <PartnerTermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path="/partner-privacy-policy"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <PartnerPrivacyPolicy />
              </Suspense>
            }
          />

          <Route
            path="/credit-agreement/:publishedFormId"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <CreditAgreement />
              </Suspense>
            }
          />

          <Route
            path="/catalogues"
            element={
              <Suspense fallback={<MuiSpinner />}>
                <CataloguesPage />
              </Suspense>
            }
          />

          {auth.authenticated ? (
            <Fragment>
              <Route
                path="/profile"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    {screen.screenWidth > 920 ? <Profile2 /> : <Profile />}
                  </Suspense>
                }
              >
                {/* <Route path="" element={<Suspense fallback={<MuiSpinner />}><P /></Suspense>} /> */}
                <Route
                  path=""
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <ProfileInformation />
                    </Suspense>
                  }
                >
                  <Route
                    path=""
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <ProfileDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="update"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <Update />
                      </Suspense>
                    }
                  />
                </Route>

                <Route
                  path="bills"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <BillsPage />
                    </Suspense>
                  }
                />
                <Route
                  path="orders"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Orders />
                    </Suspense>
                  }
                />
                <Route
                  path="orders/:id"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <O />
                    </Suspense>
                  }
                >
                  <Route
                    path=""
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <NewOrder />
                      </Suspense>
                    }
                  />
                  {/* <Route path='cancel' element={<Suspense fallback={<MuiSpinner />}><Cancel /></Suspense>} />
                <Route path='return/:id2' element={<Suspense fallback={<MuiSpinner />}><Return /></Suspense>} />
                <Route path='view-return-progress/:id2' element={<Suspense fallback={<MuiSpinner />}><ViewReturnProgress /></Suspense>} /> */}
                </Route>
                <Route
                  path="wish-list"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <WishList />
                    </Suspense>
                  }
                />
                <Route
                  path="addresses"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Addresses />
                    </Suspense>
                  }
                />
                <Route
                  path="addresses/add-new-address"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <AddNewAddress />
                    </Suspense>
                  }
                />
                <Route
                  path="addresses/:id"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <EditAddress />
                    </Suspense>
                  }
                />
                <Route
                  path="terms-and-conditions"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <TermsAndCondition2 />
                    </Suspense>
                  }
                />
                <Route
                  path="privacy-policy"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <PrivacyPolicy2 />
                    </Suspense>
                  }
                />
                <Route
                  path="faqs"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <FAQs2 />
                    </Suspense>
                  }
                />
              </Route>

              <Route
                path="/wish-list"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <WishList />
                  </Suspense>
                }
              />
              <Route
                path="/addresses"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <Addresses />
                  </Suspense>
                }
              />
              <Route
                path="/addresses/add-new-address"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <AddNewAddress />
                  </Suspense>
                }
              />
              <Route
                path="/addresses/:id"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <EditAddress />
                  </Suspense>
                }
              />
              <Route
                path="/profile-details"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <ProfileDetails />
                  </Suspense>
                }
              >
                <Route
                  path=""
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <ProfileDetail />
                    </Suspense>
                  }
                />
                <Route
                  path="update"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Update />
                    </Suspense>
                  }
                />
              </Route>

              <Route
                path="/partner"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <Partner />
                  </Suspense>
                }
              />
              <Route
                path="/partner-signup"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <PartnerSignup />
                  </Suspense>
                }
              />

              <Route
                path="/checkout"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <Checkout />
                  </Suspense>
                }
              >
                <Route
                  path="cart"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Cart />
                    </Suspense>
                  }
                >
                  <Route
                    path="order-cart"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <OrderCart />
                      </Suspense>
                    }
                  />
                  <Route
                    path="sample-cart"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <SampleCart />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="add-billing-and-shipping-details"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <ABSD />
                    </Suspense>
                  }
                >
                  <Route
                    path=""
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <AddBillingAndShippingDetails />
                      </Suspense>
                    }
                  />
                  <Route
                    path="select-address"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <SelectAddress />
                      </Suspense>
                    }
                  />
                  <Route
                    path="add-new-address"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <AddNewAddress2 />
                      </Suspense>
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Suspense fallback={<MuiSpinner />}>
                        <EditAddress2 />
                      </Suspense>
                    }
                  />
                  {/* <Route path="select-gst-number" element={<Suspense fallback={<MuiSpinner />}><SelectGSTNumber /></Suspense>} /> */}
                </Route>
                {/* <Route path="apply-discount" element={<Suspense fallback={<MuiSpinner />}><AD /></Suspense>}>
                <Route path="" element={<Suspense fallback={<MuiSpinner />}><ApplyDiscount /></Suspense>} />
                <Route path="select-discount" element={<Suspense fallback={<MuiSpinner />}><SelectDiscount /></Suspense>} />
              </Route>
              <Route path="select-payment-method" element={<Suspense fallback={<MuiSpinner />}><SelectPaymentMethod /></Suspense>} /> */}
                <Route
                  path="review-order"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <NewReviewOrder />
                    </Suspense>
                  }
                />
                <Route
                  path="order-confirmation"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <OrderConfirmation />
                    </Suspense>
                  }
                />
                {/* <Route path="select-payment-mode" element={<Suspense fallback={<MuiSpinner />}><SelectPaymentMode /></Suspense>} /> */}
              </Route>

              <Route
                path="orders"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <Orders />
                  </Suspense>
                }
              />
              <Route
                path="orders/:id"
                element={
                  <Suspense fallback={<MuiSpinner />}>
                    <O />
                  </Suspense>
                }
              >
                <Route
                  path=""
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Order />
                    </Suspense>
                  }
                />
                <Route
                  path="cancel"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Cancel />
                    </Suspense>
                  }
                />
                <Route
                  path="return/:id2"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <Return />
                    </Suspense>
                  }
                />
                <Route
                  path="view-return-progress/:id2"
                  element={
                    <Suspense fallback={<MuiSpinner />}>
                      <ViewReturnProgress />
                    </Suspense>
                  }
                />
              </Route>
            </Fragment>
          ) : null}

          {/* <Route path="/auth" element={<Suspense fallback={<MuiSpinner />}><Auth /></Suspense>}>
            <Route path="sign-in" element={<Suspense fallback={<MuiSpinner />}><SignIn /></Suspense>} />
            <Route path="verify-login-otp" element={<Suspense fallback={<MuiSpinner />}><VerifyLoginOTP /></Suspense>} />
            <Route path="signup" element={<Suspense fallback={<MuiSpinner />}><SignUp /></Suspense>} />
            <Route path="verify-phone-otp" element={<Suspense fallback={<MuiSpinner />}><VerifyPhoneOTP /></Suspense>} />
          </Route> */}

          <Route path="*" element={<Navigate to={'/'} replace={true} />} />
        </Routes>
      </div>

      {pathname?.split('/')?.[1] !== 'credit-agreement' ? (
        <Suspense fallback={<MuiSpinner />}>
          <Footer />
        </Suspense>
      ): (' ')}
      {/* <Suspense fallback={<MuiSpinner />}><EstimateButton /></Suspense> */}
    </Fragment>
  );
}

export default App;
